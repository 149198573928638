import {
  checkReferralCodeApi,
  registerUserApi,
  checkAgreeMentApi,
  updateAgreeMentApi,
  loginUserApi,
  insertUpdateProfileApi,
  getUserDataApi,
  updateCoinApi,sumAllCoinApi, getUserTeamApi
} from "../constent/api";
import * as opsService from "./Ops";

const checkReferralCode = async (token) => {
  let result = await opsService.postdata(checkReferralCodeApi);
  return result;
};
const registerUser = async (data) => {
  let result = await opsService.postdata(registerUserApi, data);
  return result;
};
const checkAgreeMent = async (data) => {
  let result = await opsService.postdata(checkAgreeMentApi, data);
  return result;
};
const updateAgreeMent = async (data) => {
  let result = await opsService.postdata(updateAgreeMentApi, data);
  return result;
};
const loginUser = async (data) => {
  let result = await opsService.postdata(loginUserApi, data);
  return result;
};
const insertUpdateProfile = async (data) => {
  const token = localStorage.getItem("jwtToken");
  let result = await opsService.postdata(insertUpdateProfileApi, data, token);
  return result;
};
const getUserData = async () => {
  const token=localStorage.getItem("jwtToken")
  let result = await opsService.getData(getUserDataApi, token);
  return result;
};
const updateCoin = async (data, token) => {
  let result = await opsService.postdata(updateCoinApi, data, token);
  return result;
};
const sumAllCoin = async (data, token) => {
  let result = await opsService.getData(sumAllCoinApi, data, token);
  return result;
};

const getUserTeam = async () => {
  const token=localStorage.getItem("jwtToken")
  let result = await opsService.getData(getUserTeamApi, token);
  return result;
};

export {
  checkReferralCode,
  registerUser,
  checkAgreeMent,
  updateAgreeMent,
  loginUser,
  insertUpdateProfile,
  getUserData,
  updateCoin,sumAllCoin,
  getUserTeam
};
