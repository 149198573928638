import React, { useEffect, useState } from "react";
import { getUserData, insertUpdateProfile } from "../../services/user";
import Header from "../widgets/Header";
import SideBar from "../widgets/SideBar";
import { toast } from "react-toastify";

function Profile() {
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const resp = await getUserData();
    if (resp.status) {
      setName(resp.data[0].name?resp.data[0].name:"");
      setEmail(resp.data[0].email?resp.data[0].email:"");
      setPhoneNumber(resp.data[0].phone_number?resp.data[0].phone_number:"")
    } else {
      // setUserDetail({});
    }
  };

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === "name") {
      setName(value);

      if (!value) {
        setNameErr("This field is required");
      } else {
        setNameErr("");
      }
    }
    if (name === "email") {
      setEmail(value);
      if (validateEmail(value)) {
        setEmailErr("");
      } else {
        setEmailErr("Invalid email address.");
      }
    }
    if (name === "phoneNumber") {
      const phoneRegex = /^[5-9]\d{9}$/;
      setPhoneNumber(value)
      if (phoneRegex.test(value)) {
        setPhoneError("");
      } else {
        setPhoneError("Phone number must be exactly 10 digits.");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!name) {
      setNameErr("This field is required");
      return;
    }

    if (phoneNumber?.length !== 10) {
      setPhoneError("Phone number must be exactly 10 digits.");
      return;
    }
  
    const profileData = {
      name,
      phone_number: phoneNumber,
      emailId:email,
    };
    const insertUpdate = await insertUpdateProfile(profileData);
    if (insertUpdate.status) {
      toast.dismiss("");
      toast.success(insertUpdate.message);
    } else {
      toast.error(insertUpdate.message);
    }
  };

  return (
    <>
      <div class="">
        <main className="s-home s-home--static">
          {/* <div class="overlay"></div> */}
          <div className="home-content dash-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12">
                  <Header />
                </div>
                <div className="col-lg-12 col-md-12 col-xs-12 das-box">
                  <div className="row">
                    <div className="col-md-3">
                      <SideBar />
                    </div>
                    <div className="col-md-9">
                      <div className="dash-right pt-4 pb-4">
                        <div className="row">
                          <div className="col-md-12 col-sm-12">
                            <h2 className="task-heading">Profile</h2>
                          </div>
                          <div className="col-md-12 col-sm-12 mt-3">
                            <div className="row">
                              <div className="col-md-6 col-sm-6 mb-3">
                                <div class="form-input">
                                  <label>Username</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="name"
                                    value={name}
                                    onChange={handleChange}                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6 mb-3">
                                <div class="form-input">
                                  <label>Email</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="email"
                                    value={email}
                                    onChange={handleChange}   
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6 mb-3">
                                <div class="form-input">
                                  <label>Mobile</label>
                                  <input
                                    type="tel"
                                    class="form-control"
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    onChange={handleChange}  
                                    maxLength={10}
                                    onKeyPress={(event) => {
                                      // Allow only numbers and one decimal point
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                  <span className="text-danger">{phoneError}</span>
                                </div>
                              </div>
                              
                              <div className="col-md-12 col-sm-12 mb-3">
                                <div class="form-input">
                                  <button
                                    type="button"
                                    class="form-control btn btn-primary"
                                    onClick={handleSubmit}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Profile;
