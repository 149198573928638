import React, { useState, useEffect } from "react";
import { Eligilibility } from "./partials/Eligilibility";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

export const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  useEffect(() => {
    let token = localStorage.getItem("jwtToken");

    if (!token) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  });

  return (
    <>
      <div className>
        <div id="particles-js"></div>
        <video
          className="video-wrap"
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source src="/assets/img/video1.mp4" type="video/mp4" />
        </video>
        <main className="s-home s-home--static">
          <div className="home-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12">
                  <div className="navi">
                    <a href="javascript:void(0);">
                      <img src="/assets/img/logo.png" className="logo-fix" />
                    </a>

                    {/* <a href="javascript:void(0);" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalThree">Eligibility Criteria</a> */}
                    <Eligilibility show={showModal} handleClose={handleClose} />
                  </div>
                  <div className="home-content-main">
                    <div className="multi-img">
                      <img
                        src="/assets/img/hero-10.png"
                        className="img-fluid working_img"
                      />
                    </div>
                    <div className="home-content-text">
                      <span>Welcome to Hawk Protocol</span>
                      <h1>
                        Thanks for joining us as we help people save, earn and
                        spend their crypto
                      </h1>
                      <p>
                        Connect your wallet to check your eligibility to claim
                        HAWK Protocol
                      </p>
                      <a href="/register" className="btn btn2 me-4">
                        Connect your wallet
                      </a>
                      <button
                        //   variant="primary"
                        onClick={handleShow}
                        className="btn btn_snone"
                      >
                        Eligibility Criteria
                      </button>
                      <a href="/assets/whitepaper.pdf" target="_blank" className="btn btn2 ms-4">
                        WhitePaper
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div
        className="about did-know p60 position-relative text-center pb-0 "
        data-scroll-index="2"
      >
        <video
          className="video-wrap"
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source src="assets/video.mp4" type="video/mp4" />
        </video>
        <div className="container ">
          <p className="  mb-5" data-aos="fade-up">
            Did you know?
          </p>
          <h2 className=" hadding mb-4 " data-aos="fade-up">
            Empowering the future with <br />
            AI-driven insights and security.
          </h2>

          <span className="text_full"></span>
        </div>
      </div>

      <div className=" position-relative powered_by" data-scroll-index="2">
        <div className="container text-center mt-md-5  ">
          <div className="">
            <h6
              className=" small_hadding mb-3 mb-md-4 text-center  position-relative"
              data-aos="fade-up"
            >
              Powered By
            </h6>
          </div>

          <div className="row align-items-center" data-aos="fade-up">
            <div className="col-lg-2 col-md-4 mb-4">
              <img
                decoding="async"
                src="assets/img/p1.png"
                alt=" Payment Gateway"
              />
            </div>
            <div className="col-lg-2 col-md-4 mb-4">
              <img
                decoding="async"
                src="assets/img/p2.png"
                alt=" Payment Gateway"
              />
            </div>
            <div className="col-lg-2 col-md-4 mb-4">
              <img
                decoding="async"
                src="assets/img/p3.png"
                alt=" Payment Gateway"
              />
            </div>
            <div className="col-lg-2 col-md-4 mb-4">
              <img
                decoding="async"
                src="assets/img/p4.png"
                alt=" Payment Gateway"
              />
            </div>
            <div className="col-lg-2 col-md-4 mb-4">
              <img
                decoding="async"
                src="assets/img/p5.png"
                alt=" Payment Gateway"
              />
            </div>
            <div className="col-lg-2 col-md-4 mb-4">
              <img
                decoding="async"
                src="assets/img/p6.png"
                alt=" Payment Gateway"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="token_distribution " id="token" data-scroll-index="3">
        <div className="container">
          <div className="row">
            <div className="col-md-5 m-auto" data-aos="zoom-in-up">
              <div className="boxx position-relative">
                <div className="boxx1"> </div>
                <div className="boxx2"> </div>
                <div className="boxx3"> </div>
                <div className="boxx4"> </div>
              </div>
            </div>
          </div>
          <h2 className="text-center hadding mb-3" data-aos="fade-up">
            Tokenomics{" "}
          </h2>
          <h4
            className="mb-4 mb-md-5 text-center  position-relative"
            data-aos="fade-up"
          >
            Total Supply: <b className="tgr ">1,000,000,000 HAWK Protocol Tokens</b>
          </h4>

          <div className="row text-center">
            <div className="col-md-5 mb-4" data-aos="fade-up-right">
              <div className="single-features2 style-two">
                <div className="">
                  <h3 className=""> 150,000,000 Tokens </h3>
                </div>
                <p>LIQUIDITY POOL</p>
              </div>
            </div>
            <div className="col-md-5 mb-4 ml-auto" data-aos="fade-up-left">
              <div className="single-features2 style-two">
                <div className="">
                  <h3 className="">400,000,000 Tokens </h3>
                </div>
                <p> LP REWARDS </p>
              </div>
            </div>

            <div className="col-md-5 mb-4" data-aos="fade-up-right">
              <div className="single-features2 style-two">
                <div className="">
                  <h3 className=""> 150,000,000 Tokens </h3>
                </div>
                <p> TEAM & ADVISOR </p>
              </div>
            </div>
            <div className="col-md-5 mb-4 ml-auto" data-aos="fade-up-left">
              <div className="single-features2 style-two">
                <div className="">
                  <h3 className=""> 150,000,000 Tokens </h3>
                </div>
                <p>DEVLOPMENT </p>
              </div>
            </div>
          </div>

          <div
            className="row"
            data-aos="fade-up"
          >
            <div className="coin_icon">
              <div className="row">
                <div className="col-md-4 m-auto" data-aos="zoom-in-up">
                  <img
                    className="coin_icon3"
                    src="assets/img/hawk.png"
                    alt="coin_icon"
                  />
                  
                </div>
              </div>
            </div>

            <div className="col-md-12 mb-4 ml-auto" data-aos="fade-up-left">
              <div className="single-features2 style-two text-center">
                <div className="">
                  <h3 className=""> 150,000,000 Tokens </h3>
                </div>
                <p>AIRDROP REWARDS </p>
              </div>
            </div>

            {/* <p className="h5 mb-4 wbba">
              {" "}
              <b>Airdrop Rewards :</b>{" "}
              150,000,000{" "}
            </p> */}

            {/* <a href="#" className="btn">
              <span> Buy $ TECH</span>
            </a>
            <a href="#" className="btn me-3">
              <span>View Chart</span>
            </a> */}
          </div>

          <div className="row overflow_hidden">
            <div className="col-md-6 mt-4" data-aos="fade-up-right">
              <div className="single-features2 tt_box">
                <h4> Key Points </h4>
                <ul>
                  <li>
                    {" "}
                    Selling Limit: Holders can sell only 24% of their total
                    holdings.
                  </li>
                  <li>
                    Monthly Vesting: Team and partnerships receive 2% monthly.
                  </li>
                  <li>Development Fund Use: For community building only.</li>
                </ul>
              </div>
            </div>

            <div className="col-md-6 mt-4 " data-aos="fade-up-left">
              <div className="single-features2 tt_box">
                <h4> Detailed Explanation: </h4>
                <ul>
                  <li>
                    {" "}
                    Initial Liquidity Pool: Ensures stable and smooth trading on
                    exchanges.
                  </li>
                  <li>
                    Community Incentive & Reward: Encourages active
                    participation and contribution from Hawk Protocol Guardians.{" "}
                  </li>
                  <li>
                    {" "}
                    Strategic Partnerships & Development Fund: Enhances platform
                    capabilities and fosters growth.{" "}
                  </li>
                  <li>
                    Team & Advisors: Rewards those contributing to the project's
                    success.{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="position-relative p60 text-center pb-0 overflow_hidden"
        data-scroll-index="4"
      >
        <div className="container">
          <h2 className="text-center mb-4 hadding" data-aos="fade-up">
            {" "}
            <span className="fwb">Our Experts</span>{" "}
          </h2>
          <div className="row">
            <div className="col-md-4" data-aos="fade-right">
              <div className="team_box single-features2">
                <img src="assets/img/team-01.jpg" alt="team" />
                <h4>Olivia </h4>
                <p>
                I’m seeing great returns using HAWK Protocol tokens, with reliable performance and low transaction fees.

                </p>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-up">
              <div className="team_box single-features2">
                <img src="assets/img/team-02.jpg" alt="team" />
                <h4>Freddie</h4>
                <p>
                Thanks to HAWK Protocol tokens, I now enjoy effortless, secure, and scalable blockchain transactions.

                </p>
              </div>
            </div>
            <div className="col-md-4" data-aos="fade-left">
              <div className="team_box single-features2">
                <img src="assets/img/team-03.jpg" alt="team" />
                <h4>Amelia </h4>
                <p>
                HAWK Protocol tokens have transformed my investment experience and delivered impressive rewards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="roadmap position-relative p60 overflow_hidden pb-0"
        id="token"
        data-scroll-index="4"
      >
        <div className="container">
          <h2 className="text-center mb-4 hadding" data-aos="fade-up">
            {" "}
            <span className="fwb">Roadmap</span>{" "}
          </h2>
          <div className="row">
            <div className="col-md-12 m-auto">
              <div className="roadmap_box">
                <ul id="timeline">
                  <li className="work" data-aos="fade-up">
                    <div className="relative">
                      <div className="timeline-icon">
                        <i className="bi bi-database-fill"></i>
                      </div>
                      <label for="work5">
                        Successfully launch the Hawk Protocol token on the main net.
                        <p>
                          Successfully launch the Hawk Protocol token on the main net.
                        </p>
                      </label>

                      <span className="date">Febuary 2025</span>
                      <span className="circle"></span>
                    </div>
                  </li>
                  <li className="work" data-aos="fade-up">
                    <div className="relative">
                      <div className="timeline-icon">
                        <i className="bi bi-currency-dollar"></i>
                      </div>
                      <label for="work4">
                        Liquidity Pool of 70 Million USDT
                        <p>
                          The initiative to create liquidity pool owned by
                          multiple users by burning LP Tokens
                        </p>
                      </label>
                      <span className="date">March 2025</span>
                      <span className="circle"></span>
                    </div>
                  </li>
                  <li className="work" data-aos="fade-up">
                    <div className="relative">
                      <div className="timeline-icon">
                        <i className="bi bi-rocket-takeoff"></i>
                      </div>
                      <label for="work3">
                        Projects testnet Launch
                        <p>
                          Launch of various AI-Driven projects on blockchain
                          Testnet
                        </p>
                      </label>
                      <span className="date">April 2025</span>
                      <span className="circle"></span>
                    </div>
                  </li>
                  <li className="work" data-aos="fade-up">
                    <div className="relative">
                      <div className="timeline-icon">
                        <i className="bi bi-people"></i>
                      </div>
                      <label for="work2">
                        {" "}
                        AI-User Acquisition Campaign
                        <p>
                          Launch a comprehensive marketing campaign to attract
                          new users and expand the Hawk Protocol community.
                        </p>
                      </label>
                      <span className="date">May 2025</span>
                      <span className="circle"></span>
                    </div>
                  </li>

                  <li className="work" data-aos="fade-up">
                    <div className="relative">
                      <div className="timeline-icon">
                        <i className="bi bi-gear-wide-connected"></i>
                      </div>
                      <label for="work2">
                        {" "}
                        Strategic Partnerships
                        <p>
                          Form strategic partnerships with key industry players
                          to enhance the platform's capabilities and reach.
                        </p>
                      </label>
                      <span className="date">June 2025</span>
                      <span className="circle"></span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section
        className="faq p60 pb-0 position-relative overflow_hidden"
        data-scroll-index="5"
      >
        <div className="container ">
          <h2 className="text-center hadding mb-md-4" data-aos="fade-up">
            FAQ
          </h2>
          <div className="row">
            <div className="  col-md-8 m-auto">
              <div
                className="accordion md-accordion style-2"
                id="accordionEx"
                role="tablist"
                aria-multiselectable="true"
              >
                <div className="card" data-aos="fade-up">
                  <div className="card-header" role="tab" id="headingOne1">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseOne1"
                      aria-expanded="true"
                      aria-controls="collapseOne1"
                    >
                      What is HAWK Protocol?
                    </a>
                  </div>
                  <div
                    id="collapseOne1"
                    className="collapse "
                    role="tabpanel"
                    aria-labelledby="headingOne1"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      HAWK Protocol is a revolutionary platform leveraging AI
                      technology and blockchain to provide unparalleled insights
                      and secure transactions.
                    </div>
                  </div>
                </div>

                <div className="card" data-aos="fade-up">
                  <div className="card-header" role="tab" id="headingTwo2">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo2"
                      aria-expanded="false"
                      aria-controls="collapseTwo2"
                    >
                      How Does HAWK Protocol Work?
                    </a>
                  </div>
                  <div
                    id="collapseTwo2"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo2"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      HAWK Protocol combines advanced AI algorithms with blockchain
                      technology to analyze data and generate actionable
                      insights.
                    </div>
                  </div>
                </div>
                <div className="card" data-aos="fade-up">
                  <div className="card-header" role="tab" id="headingTwo3">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo3"
                      aria-expanded="false"
                      aria-controls="collapseTwo3"
                    >
                      What is the HAWK Protocol Token?
                    </a>
                  </div>
                  <div
                    id="collapseTwo3"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo3"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      The HAWK Protocol token is the core of our ecosystem,
                      facilitating transactions and offering rewards to
                      participants.
                    </div>
                  </div>
                </div>
                {/*<div className="card" data-aos="fade-up">
                  <div className="card-header" role="tab" id="headingTwo4">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo4"
                      aria-expanded="false"
                      aria-controls="collapseTwo4"
                    >
                      Who are HAWK Protocol?{" "}
                    </a>
                  </div>
                  <div
                    id="collapseTwo4"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo2"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      HAWK Protocol are selected participants who actively
                      contribute to the ecosystem. They can withdraw rewards
                      from the Community Incentive and Reward pool based on a
                      set distribution algorithm and proof of LP Guardian Note.
                    </div>
                  </div>
                </div>

                 <div className="card" data-aos="fade-up">
                  <div className="card-header" role="tab" id="headingTwo5">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo5"
                      aria-expanded="false"
                      aria-controls="collapseTwo5"
                    >
                      How Can I Become an HAWK Protocol?{" "}
                    </a>
                  </div>
                  <div
                    id="collapseTwo5"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo5"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      Join our community and actively participate in our
                      initiatives. Provide proof of LP Guardian Note to qualify
                      for Hawk Protocol Guardian status.
                    </div>
                  </div>
                </div> */}

                <div className="card" data-aos="fade-up">
                  <div className="card-header" role="tab" id="headingTwo6">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo6"
                      aria-expanded="false"
                      aria-controls="collapseTwo6"
                    >
                      What is the Selling Limit?{" "}
                    </a>
                  </div>
                  <div
                    id="collapseTwo6"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo6"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      Holders are limited to selling only 24% of their total
                      holdings to ensure market stability.
                    </div>
                  </div>
                </div>

                <div className="card" data-aos="fade-up">
                  <div className="card-header" role="tab" id="headingTwo7">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo7"
                      aria-expanded="false"
                      aria-controls="collapseTwo7"
                    >
                      What is the Monthly Vesting Schedule?{" "}
                    </a>
                  </div>
                  <div
                    id="collapseTwo7"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo7"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      Team and partnerships receive 2% of their holdings monthly
                      to promote long-term commitment.
                    </div>
                  </div>
                </div>
                <div className="card" data-aos="fade-up">
                  <div className="card-header" role="tab" id="headingTwo8">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo8"
                      aria-expanded="false"
                      aria-controls="collapseTwo8"
                    >
                      How Will the Development Fund Be Used?
                    </a>
                  </div>
                  <div
                    id="collapseTwo8"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo8"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      {" "}
                      The Development Fund will be used exclusively for
                      community building initiatives.
                    </div>
                  </div>
                </div>
                <div className="card" data-aos="fade-up">
                  <div className="card-header" role="tab" id="headingTwo9">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordionEx"
                      href="#collapseTwo9"
                      aria-expanded="false"
                      aria-controls="collapseTwo9"
                    >
                      How Can I Get Started?{" "}
                    </a>
                  </div>
                  <div
                    id="collapseTwo9"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo9"
                    data-parent="#accordionEx"
                  >
                    <div className="card-body">
                      Sign up on our platform, and start
                      exploring the world of HAWK Protocol.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className=" position-relative p60 overflow_hidden"
        id="token"
        data-scroll-index="4"
      >
        <div className="container">
          <h2 className="text-center mb-4 mb-md-5 hadding" data-aos="fade-up">
            Contact{" "}
          </h2>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="single-features2" data-aos="fade-up-right">
                <h5 className="mb-3">Get in Touch</h5>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="name"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    name="name"
                    value=""
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Message"
                  ></textarea>
                </div>
                <div className="">
                  <button type="submit" className="btn">
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="single-features2" data-aos="fade-up-left">
                <h5 className="mb-3">Email</h5>
                <a href="mailto:support@hawkprotocol.live">support@hawkprotocol.live</a>

                <h5 className="mb-3 mt-5">Social Media Links</h5>
                <div
                  className="vertical-social wow fadeInDown  animated"
                  data-wow-delay="0.1s"
                >
                  <ul className="social_icon ">
                    <li>
                      <a href="https://x.com/hawk__protocol" target="_blank">
                        <i className="bi bi-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/share/ciGniG8RzTcDRrFh/" target="_blank">
                        <i className="bi bi-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/hawkprotocol.live?igsh=MXQyb284Y211Z3l3aQ==" target="_blank">
                        <i className="bi bi-instagram"></i>
                      </a>
                    </li>
                  
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
