import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export const Eligilibility = ({ show, handleClose }) => {
  console.log(show, "show");
  return (
    <>
      <Modal show={show} onHide={handleClose} className="eligible-view">
        <Modal.Body>
          <div className="wallet-connet">
            <div className="wallet-connet-r">
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
                aria-label="Close"
              ></button>
              <div className="wallet-connet-r-box">
                <h4>Eligibility Criteria</h4>
                <p className="eli-text">
                  The following criteria was used to determine eligibility:
                </p>
                <div className="cobra-tream-eligi">
                  <div className="cobra-eligi">
                    <h3>HAWK Protocol </h3>
                    <p>You have followed all the Social Handles of HAWK Protocol and Retweet the Pinned Post.</p>
                  </div>
                  <div className="cobra-eligi">
                    <h3>Referrals </h3>
                    <p>You referred one or more friends to HAWK Protocol.</p>
                  </div>
                  {/* <div className="cobra-eligi">
                    <h3>DeFi rewards</h3>
                    <p>
                      You hold a position in a participating eETH or weETH DeFi
                      pool or vault
                    </p>
                  </div> */}
                  <div className="cobra-eligi">
                    <h3>Active Wallets </h3>
                    <p>Only Active Wallets will be rewarded in the Airdrop.</p>
                  </div>
                </div>
                <a href="/" className="btn btn-secondary mt-3">
                  Ok
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
