import React,{useEffect, useState} from "react";
import {
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";
import { useAuth } from "../../AuthContext";
import { bnbChainId, bnbRpcUrl, explorerUrlBsc,projectId } from "../../constent";

function Header() {
  const { authenticated, wallet_address, logout,handleSideBar,sideBarMenu } = useAuth();
  const { address, isConnected } = useWeb3ModalAccount();
  useEffect(() => {
    if (authenticated && wallet_address) {
      const checkConnection = () => {
        if (!isConnected) {
          logout();
          window.location.href = "/register";
        } else if (address && address !== wallet_address) {
          logout();
          window.location.href = "/register";
        }
      };
      const timeout = setTimeout(checkConnection, 4000);
      return () => clearTimeout(timeout);
    }
  }, [address, isConnected, authenticated, wallet_address, logout]);

  const mainnet = [
    {
      chainId: Number(bnbChainId),
      name: "Bnb mainnet",
      currency: "BNB",
      explorerUrl: explorerUrlBsc,
      rpcUrl: bnbRpcUrl,
    },
  ];

  const metadata = {
    name: "",
    description: "",
    url: "https://hawkprotocol.com/",
    icons: ["https://hawkprotocol.com/assets/images/favicon.png"],
  };

  const modal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  const disconnect = () => {
    if (isConnected) {
      modal.open();
    }
  };



  return (
    <div>
      <div className="navi navi-head">
        <a href="/">
          <img src="/assets/img/logo.png" className="logo-fix" />
        </a>
        <button className="nav-link btn btn-primary" onClick={disconnect}>
          {`${wallet_address.substring(0, 7)}...${wallet_address.substring(
            wallet_address.length - 7
          )}`}
        </button>
        <a href="javascript:void(0);" className={`menu-line ${sideBarMenu? "active":""}`} onClick={handleSideBar}>
          <span className="line-menu-one"></span>
          <span className="line-menu-two"></span>
          <span className="line-menu-three"></span>
        </a>
      </div>
    </div>
  );
}

export default Header;
