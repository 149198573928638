import React from 'react'
import { Link } from 'react-router-dom'
import {
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";
import { bnbChainId, bnbRpcUrl,projectId } from '../../constent';
import { useAuth } from '../../AuthContext';

function SideBar() {
  const { sideBarMenu,handleSideBar } = useAuth();
  const { isConnected } = useWeb3ModalAccount();

  const mainnet = [
    {
      chainId: bnbChainId,
      name: "Bnb Mainnet",
      currency: "BNB",
      explorerUrl: "https://etherscan.io/",

      rpcUrl: bnbRpcUrl,
    },
  ];

  const metadata = {
    name: "Hawk Protocol",
    description: "Hawk Protocol",
    url: "https://hawkprotocol.xyz/",
    icons: ["https://hawkprotocol.xyz/html/images/crudo_Logo.svg"],
  };

  const modal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  const disconnect = () => {
    if (isConnected) {
      modal.open();
    }
  };
  return (
    <>
      <div className={`sidebar-menu ${sideBarMenu?"active":""}`}>
        <ul>
          <li>
              <Link to="/dashboard" onClick={handleSideBar}><img src='/assets/img/dash-icon.svg' /> Dashboard</Link>
          </li>
          <li>
              <Link to="/task" onClick={handleSideBar}><img className='taskImg' src='/assets/img/task-icon.svg' /> Task</Link>
          </li>
          <li>
              <Link to="/resources" onClick={handleSideBar}><img className='workImg' src='/assets/img/work-icon.svg' />Resources</Link>
          </li>
          <li>
              <Link to="/profile" onClick={handleSideBar}><img className='workImg' src='/assets/img/work-icon.svg' />Profile</Link>
          </li>
          <li>
              <Link to="/team" onClick={handleSideBar}><img className='workImg' src='/assets/img/work-icon.svg' />Team</Link>
          </li>
          <li>
              <Link to="/referral-report" onClick={handleSideBar}><img className='workImg' src='/assets/img/work-icon.svg' />Referral Report</Link>
          </li>
          <li>
              <a href="#" onClick={disconnect}><img className='workImg' src='/assets/img/turn-off.svg' />Logout</a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default SideBar
