import React, { useEffect, useState } from "react";
import Header from "../widgets/Header";
import SideBar from "../widgets/SideBar";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { getUserTeam } from "../../services/user";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";

function Team() {
  const navigate = useNavigate();
  const { authenticated } = useAuth();
 const [data, setData] = useState([])

 useEffect(() => {
  if (!authenticated) {
    navigate(`/register`);
  } 
  getData();
 }, [])

  const getData=async()=>{
    const result =await getUserTeam()
    if(result.status){
      setData(result.data)
    }
    else{
      setData([])
    }
  }
 

 const columns = [
  {
    key: "Sr No.",
    text: "Sr. No.",
    className: "sr_no.",
    align: "left",
    sortable: true,
    cell: (row, index) => index + 1,
  },
  {
    key: "wallet_address",
    text: "Wallet Address",
    className: "sr_no.",
    align: "left",
    sortable: true,
  },
  {
      key: "total_hawks",
      text: "Total Hawks",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
  {
    key: "registerDate",
    text: "Register Date",
    className: "color",
    align: "left",
    sortable: true,
    cell: (record) => {
      return <>{moment(record.created_at).format("LLL")}</>;
    },
  },
 
];

const config = {
  page_size: 10,
  length_menu: [10, 20, 50],
  filename: "Download",
  no_data_text: "No user found!",
  button: {
    print: false,
    csv: false,
  },
  language: {
    // length_menu: "Show MENU result per page",
    filter: "Search in records...",
    // info: "Showing START to END of TOTAL records",
    pagination: {
      first: "First",
      previous: "Previous",
      next: "Next",
      last: "Last",
    },
  },
  show_length_menu: true,
  show_filter: true,
  show_pagination: true,
  show_info: true,
};

  return (
    <>
      <div class="">
        <main className="s-home s-home--static">
          {/* <div class="overlay"></div> */}
          <div className="home-content dash-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12">
                  <Header />
                </div>
                <div className="col-lg-12 col-md-12 col-xs-12 das-box">
                <div className="row">
                  <div className="col-md-3">
                    <SideBar />
                  </div>
                  <div className="col-md-9">
                    <div className="dash-right team-table pt-4 pb-4">
                      <div className="table-design">
                        <ReactDatatable
                          config={config}
                          records={data}
                          columns={columns}
                        
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Team;
