import { addUserTaskApi, getReferralReportapi, getUserTaskApi } from "../constent/api";
import * as opsService from "./Ops";
  
  export const addUserTask=async(data)=>{
    const token=localStorage.getItem("jwtToken")
    let result = await opsService.postdata(addUserTaskApi, data,token);
    return result;
  }
 
  export const getUserTask=async(data)=>{
    const token=localStorage.getItem("jwtToken")
    let result = await opsService.postdata(getUserTaskApi, data,token);
    return result;
  }
  export const getReferralReport=async()=>{
    const token=localStorage.getItem("jwtToken")
    let result = await opsService.getData(getReferralReportapi,token);
    return result;
  }