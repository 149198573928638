import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Register } from "./components/auth/Register";
import { Home } from "./components/Home";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Dashboard from "./components/pages/Dashboard";
import Team from "./components/pages/Team";
import Task from "./components/pages/Task";
import Profile from "./components/pages/Profile";
import Resources from "./components/pages/Resources";
import { useAuth } from "./AuthContext";
import { useEffect } from "react";
import ReferralReport from "./components/pages/ReferalReport";


function App() {
  const { login } = useAuth();

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      login();
    }
  }, [login]);

  return (
    <div className="App">
         <Router>
        <Routes>
        <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/team" element={<Team />} />
          <Route path="/task" element={<Task />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/referral-report" element={<ReferralReport />} />
          <Route path="/register/:walletAddress" element={<Register />} />
        </Routes>
      </Router>
      <ToastContainer
      className= 'custom-toast'
      position= "top-center"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
    </div>
  );
}

export default App;
