import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { getTaskData } from "../../services/task";
import Header from "../widgets/Header";
import SideBar from "../widgets/SideBar";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

function Resources() {
  const navigate = useNavigate();
  const { authenticated } = useAuth();
  const [record, setRecord] = useState([]);

  useEffect(() => {
    if (!authenticated) {
      navigate(`/register`);
    } else {
    }
    getData();
  }, []);

  const getData = async () => {
    const result = await getTaskData();
    if (result.status) {
      setRecord(result.data);
    } else {
      setRecord([]);
    }
  };

  const copyToClipboard = (value) => {
    if (value) {
      toast.dismiss();
      toast.success("Description copied successfully");
      copy(value);
    }
  };

  const downloadImage = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div class="">
      <main className="s-home s-home--static">
        <div className="home-content dash-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12">
                <Header />
              </div>
              <div className="col-lg-12 col-md-12 col-xs-12 das-box">
                <div className="row">
                  <div className="col-md-3">
                    <SideBar />
                  </div>
                  <div className="col-md-9">
                    <div className="dash-right pt-4 pb-4">
                      <div className="row">
                        {record?.map((list) => {
                          return (
                            <div className="col-md-4 col-sm-6">
                              <div className="reso-task">
                                <h3>{list.name}</h3>
                                <span>
                                  <img src={list.image} />
                                  <b
                                    className="downIcon"
                                    onClick={() => {
                                      downloadImage(list.image);
                                    }}
                                  >
                                    <img
                                      src="/assets/img/down-icon.svg"
                                      alt=""
                                    />
                                  </b>
                                </span>
                                <div className="reso-task-box">
                                  <h4>
                                    Description{" "}
                                    {/* <a
                                      href="javascript:void(0);"
                                      onClick={() => {
                                        copyToClipboard(list.description);
                                      }}
                                    >
                                      <img src="/assets/img/copy-icon.svg" />
                                    </a> */}
                                  </h4>
                                  <div dangerouslySetInnerHTML={{ __html: list.description }} />
                           
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Resources;
